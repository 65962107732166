import { Card, FormGroup, InputLabel, FormControlLabel, Checkbox } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/Store";

export default observer(function ReloadSettings() {
    const { reloadOptionsStore } = useStore();

    const handleReloadAppBundlesChange = (event: any) => {
        if (event.target.checked) {
            reloadOptionsStore.setReloadAppBundles(true);
        }
        else {
            reloadOptionsStore.setReloadAppBundles(false);
        }
    }

    return (<>
        <Card sx={{ m: 1 }}>
            <FormGroup>
                <InputLabel sx={{ m: 1 }}>Reload Settings</InputLabel>
                <FormControlLabel sx={{ m: 1 }} control={<Checkbox onChange={handleReloadAppBundlesChange} />} label="Reload Application Bundles" />
            </FormGroup>
        </Card>
    </>)
});