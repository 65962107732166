import { makeAutoObservable } from "mobx";

export default class ReloadOptionStore {
    reloadAppBundles: boolean = false;
    loadViewer: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setReloadAppBundles(value: boolean) {
        this.reloadAppBundles = value;
    }
}
