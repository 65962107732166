import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import WindowsSettingsData from "../models/WindowsSettingsData";
import WindowViewerData from "../models/WindowViewerData";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError) => {
    console.log(error.response);
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (typeof data === "string") {
          toast.error(data);
        }
        break;
    }

    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const requests = {
  get: <T>(url: string) =>
    axios.get<T>(url, { withCredentials: true }).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body, { withCredentials: true }).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Authentication = {
  getAuthUri: () => requests.get<string>("/auth/GetAuthUri"),
  isAuth: () => requests.get<boolean>("/auth/isAuth"),
  getUser: () => requests.get<any>("/auth/getUser"),
};

const Oss = {
  uploadWebcalcData: (formData: FormData) =>
    requests.post<any>("/oss/uploadWebcalcData", formData),
};

const DesignAutomationAssembly = {
  createAssembly: (windowsSettingsData: WindowsSettingsData) => requests.post<any>("/automationAssembly/createAssembly", windowsSettingsData),
  loadViewer: (WindowViewerData: WindowViewerData) => requests.post<any>("/automationAssembly/loadViewer", WindowViewerData)
}

const DesignAutomationRfaExport = {
  createRevitFamily: (windowsSettingsData: WindowsSettingsData) => {
    requests.post<any>("/automationRfaExport/createRevitFamily", windowsSettingsData);
  }
}

const AutodeskCloud = {
  loadTemplateFiles: () =>
    requests.get<any>("/autodeskcloud/loadtemplatefiles"),
};

const agent = {
  Authentication: Authentication,
  Oss: Oss,
  AutodeskCloud: AutodeskCloud,
  DesignAutomationAssembly: DesignAutomationAssembly,
  DesignAutomationRfaExport: DesignAutomationRfaExport
};

export default agent;
