import { InputLabel, Stack, Chip } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import WindowViewerData from "../models/WindowViewerData";
import { ForgeViewer } from "../scripts/viewer";


interface Props {
    windowViewerDataList: WindowViewerData[],
    token: string
}

export default observer(function SelectorViewer({ windowViewerDataList, token }: Props) {
    const [activeForgeViewerId, setActiveForgeViewerId] = useState("");

    const handleAssemblyViewerClick = (element: any) => {
        const windowViewerData = windowViewerDataList?.filter(w => w.selectedWindow === element.target.textContent)[0];
        setActiveForgeViewerId("forgeViewer" + windowViewerData?.viewerUrn)
    }

    if (windowViewerDataList) {
        return (
            <>
                <InputLabel>Select Assembly To View</InputLabel>
                <Stack direction="row" spacing={1}>
                    {windowViewerDataList!.map((windowViewerItem) => {
                        const key = 'forgeViewer' + windowViewerItem.viewerUrn!;
                        return (
                            <Chip label={windowViewerItem.selectedWindow}
                                key={key}
                                onClick={handleAssemblyViewerClick}
                                variant={activeForgeViewerId === key ? "outlined" : "filled"} />
                        );
                    })}
                </Stack>
                <>
                    {windowViewerDataList!.map((windowViewerItem, index) => {
                        const urn = windowViewerItem.viewerUrn!;
                        return (
                            activeForgeViewerId === "forgeViewer" + windowViewerItem.viewerUrn &&
                            <ForgeViewer key={index} {...{ token, urn }} />
                        );
                    })}
                </>
            </>
        )
    }
    return (
        <></>
    )
});