import "./App.css";
import Login from "./components/Login";
import { useEffect, useState } from "react";
import agent from "./api/agent";
import User from "./components/User";
import WindowDataInput from "./components/WindowDataInput";
import InventorAssembly from "./components/CreatorInventorAssembly";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    agent.Authentication.isAuth().then((value) => setIsAuth(value));
  });

  return (
    <>
      <ToastContainer position="bottom-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
      />
      {!isAuth && <Login />}
      {isAuth && (
        <>
          <User />
          <WindowDataInput />
          <InventorAssembly />
        </>
      )}
    </>
  );
}

export default App;
