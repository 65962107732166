import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CircularProgress,
  InputLabel,
  Link,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import agent from "../api/agent";
import BrowserSettingsData from "../models/BrowserSettingsData";
import WindowsSettingsData from "../models/WindowsSettingsData";
import WindowViewerData from "../models/WindowViewerData";
import { useStore } from "../stores/Store";
import ReloadSettings from "./ReloadSettings";
import LinkRevitFamilies from "./LinkRevitFamilies";
import SelectorViewer from "./SelectorViewer";
import { validateEmail } from "../scripts/validation/emailValidation";

export default observer(function CreatorInventorAssembly() {
  const { windowsPositionsStore, reloadOptionsStore } = useStore();
  const [canUpload, setCanUpload] = useState(false);

  useEffect(() => {
    setCanUpload(windowsPositionsStore.anyWindowPositionSelected());
  }, [windowsPositionsStore, windowsPositionsStore.selectedWindowPositions])

  const [uploading, setUploading] = useState(false);
  const [connection, setConnection] = useState<any>(undefined);
  const [connectionId, setConnectionId] = useState("");

  const [complete, setIsComplete] = useState(false);

  const [assemblyZipUrl, setAssemblyZipUrl] = useState("");

  const [rfaUrl, setRfaUrl] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [email, setEmail] = useState("");

  const [token, setToken] = useState<string>("");

  const [windowViewerDataList, setWindowViewerDataList] = useState<WindowViewerData[]>();

  const browserSettingsData: BrowserSettingsData = {
    browserConnectionId: connectionId,
    reloadAppBundles: reloadOptionsStore.reloadAppBundles,
    loadViewer: reloadOptionsStore.loadViewer
  }

  const handleCreateAssembly = () => {
    setIsComplete(false);
    setAssemblyZipUrl("");
    setUploading(true);

    let windowsSettingsData: WindowsSettingsData = {
      positions: windowsPositionsStore.selectedWindowPositions,
      browserSettingsData: browserSettingsData,
      emailToSendLink: email
    };

    agent.DesignAutomationAssembly.createAssembly(windowsSettingsData);
  };

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_API_URL + "/signalr/forgecommunication", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();
    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(function () {
          connection.invoke("getConnectionId").then(function (id: any) {
            setConnectionId(id);
          });
        })
        .catch((error: any) => console.log(error));
      connection.serverTimeoutInMilliseconds = 960000;
      connection.on("downloadAssemblyZip", (url: any) => {
        setAssemblyZipUrl(url);
      });

      connection.on("showAssembliesInViewer", (windowViewerDataList: WindowViewerData[], token: string) => {
        console.log("windowViewerDataList");
        console.log(windowViewerDataList);
        setWindowViewerDataList(windowViewerDataList!);
        setToken(token);
      });

      connection.on("downloadRfa", (rfaUrl: string) => {
        setRfaUrl(rfaUrl);
      });

      connection.on("onComplete", (isComplete: boolean) => {
        setUploading(false);
        setIsComplete(true);
      });
    }
  }, [connection]);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    if (validateEmail(value)) {
      setIsEmailValid(true);
      setEmail(value);
    }
    else {
      setIsEmailValid(false);
    }
  }

  return (
    <>
      <ReloadSettings />

      <Card sx={{ m: 1 }}>
        <TextField id="outlined-basic" label="Email" variant="outlined" style={{ margin: "5px" }} onChange={handleEmailChange} error={!isEmailValid} />

        <InputLabel htmlFor="inputFile" style={{ margin: "5px" }}>
          Create Inventor Assembly
        </InputLabel>
        <LoadingButton
          loading={uploading}
          disabled={!canUpload}
          variant="contained"
          component="label"
          style={{ margin: "5px" }}
          onClick={handleCreateAssembly}
        >
          Start
        </LoadingButton>

        {complete && (
          <>
            <br />
            <Link
              underline="always"
              variant="overline"
              href={assemblyZipUrl}
              style={{ margin: "5px" }}
            >
              Download Inventor Assemblies Zip File
            </Link>

            <br />
            <LinkRevitFamilies rfaUrl={rfaUrl} />

            {windowViewerDataList && <>
              <SelectorViewer windowViewerDataList={windowViewerDataList!} token={token} />
            </>}

            {!windowViewerDataList && <>
              <InputLabel style={{ margin: "5px" }}>Loading Window Visualization...</InputLabel>
              <CircularProgress style={{ margin: "5px" }} />
            </>}
          </>
        )}
      </Card>
    </>
  );
});
