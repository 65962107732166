import { createContext, useContext } from "react";
import ReloadOptionsStore from "./ReloadOptionsStore";
import WindowsPositionsStore from "./WindowsPositionsStore";

interface Store {
    windowsPositionsStore: WindowsPositionsStore;
    reloadOptionsStore: ReloadOptionsStore;
}

export const store: Store = {
    windowsPositionsStore: new WindowsPositionsStore(),
    reloadOptionsStore: new ReloadOptionsStore()
};

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}