import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useState } from "react";
import { useStore } from "../stores/Store";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const selectAllValue = "Select All";

export default function SelectorWindowPositions() {
    const { windowsPositionsStore } = useStore();
    const [selectedWindowPositions, setSelectedWindowPositions] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleChange = (event: any) => {
        const value = event.target.value;

        if (value === undefined) {
            return;
        }

        if (value[value.length - 1] === selectAllValue) {

            setSelectAll(!selectAll);
            const selectAllPositions = !selectAll;

            if (selectAllPositions) {
                setSelectedWindowPositions(windowsPositionsStore.allWindowsPositions);
                windowsPositionsStore.setSelectedWindowPositions(windowsPositionsStore.allWindowsPositions);
            }
            else {
                setSelectedWindowPositions([]);
                windowsPositionsStore.setSelectedWindowPositions([]);
            }
        }
        else {
            const codes = typeof value === 'string' ? value.split(',') : value;
            windowsPositionsStore.setSelectedWindowPositions(codes);
            setSelectedWindowPositions(codes);
        }

    };

    return (<>
        <Box sx={{ minWidth: 100, m: 1 }}>
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native" id="mutiple-select-label" >
                    Window Positions
                </InputLabel>

                <Select
                    labelId="mutiple-select-label"
                    id="mutiple-select"
                    multiple
                    value={selectedWindowPositions}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    <MenuItem key={selectAllValue} value={selectAllValue}>
                        <Checkbox checked={selectAll} />
                        <ListItemText primary="Select All" />
                    </MenuItem>
                    {windowsPositionsStore.allWindowsPositions.map((positionCode, index) => (
                        <MenuItem key={index} value={positionCode}>
                            <Checkbox checked={windowsPositionsStore.selectedWindowPositions.indexOf(positionCode) > -1} />
                            <ListItemText primary={positionCode} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    </>)
}