import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  InputLabel,
  Popover,
  Typography,
} from "@mui/material";

import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { toast } from 'react-toastify';

import { useState } from "react";
import agent from "../api/agent";
import { useStore } from "../stores/Store";
import SelectWindowPositions from "./SelectorWindowPositions";
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

export default observer(function WindowDataInput() {
  const { windowsPositionsStore } = useStore();
  const [missingItems, setMissingItems] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleFileSelected = (event: any) => {
    setUploading(true);

    const formData = new FormData();
    const file = event.target.files[0];
    formData.append("fileToUpload", file);
    agent.Oss.uploadWebcalcData(formData).then((windowSelectionResult) => {
      windowsPositionsStore.setAllWindowsPositions(windowSelectionResult.PositionCodes);
      setMissingItems(windowSelectionResult.TotalItemsInfo);
      showToastMessages(windowSelectionResult);
      setUploading(false);
    });
  };

  const showToastMessages = (windowSelectionResult: any) => {
    toast.dismiss();
    console.log(windowSelectionResult);
    showErrors(windowSelectionResult.ErrorItemsInfo);
    showWarnings(windowSelectionResult.WarningItemsInfo);
    showSuccess(windowSelectionResult.SuccessItemsInfo);
  }

  const showErrors = (missingItems: string[]) => {
    missingItems.forEach(item => toast.error(item));
  }

  const showWarnings = (missingItems: string[]) => {
    missingItems.forEach(item => toast.warning(item));
  }

  const showSuccess = (missingItems: string[]) => {
    missingItems.forEach(item => toast.success(item));
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card variant="outlined" sx={{ m: 1 }}>
        <InputLabel htmlFor="inputFile" style={{ margin: "5px" }}>
          Upload Webcalc Excel or Json File
        </InputLabel>
        <LoadingButton
          loading={uploading}
          variant="contained"
          component="label"
          style={{ margin: "5px" }}
        >
          <input type="file" id="inputFile" onChange={handleFileSelected}></input>
        </LoadingButton>

        {windowsPositionsStore.allWindowsPositions !== undefined && windowsPositionsStore.allWindowsPositions.length > 0 && (
          <>
            <SelectWindowPositions />
            <br />

            <Button aria-describedby={id} variant="contained" onClick={handleClick} sx={{ m: 1 }}>
              Show Missing Parts
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {missingItems.length > 0 && missingItems.map((missingItem, index) => {
                return <Typography key={index} sx={{ p: 2 }}>{missingItem}</Typography>
              })};
            </Popover>
          </>
        )}
      </Card>
    </>
  );
});
function setConnection(connect: HubConnection) {
  throw new Error("Function not implemented.");
}

