import { Button } from "@mui/material";
import agent from "../api/agent";

export default function Login() {
  function handleLogin() {
    agent.Authentication.getAuthUri().then((uri) =>
      window.location.replace(uri)
    );
  }

  return (
    <>
      <Button variant="contained" onClick={handleLogin}>
        Login
      </Button>
    </>
  );
}
