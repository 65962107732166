import { makeAutoObservable } from "mobx";

export default class WindowsPositionsStore {
    selectedWindowPositions: number[] = []
    allWindowsPositions: number[] = []

    constructor() {
        makeAutoObservable(this);
    }

    setSelectedWindowPositions = (positions: number[]) => {
        this.selectedWindowPositions = positions;
    }

    setAllWindowsPositions = (positions: number[]) => {
        this.allWindowsPositions = positions;
    }

    anyWindowPositionSelected = () => {
        return this.selectedWindowPositions.length > 0;
    }
}

