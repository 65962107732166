import { Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import agent from "../api/agent";

export default function User() {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    agent.Authentication.getUser().then((value) => setUser(value));
  }, []);

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Card>
          <CardContent>
            <Typography>Welcome!</Typography>
            <Typography>
              {user && user.firstName} {user && user.lastName}
            </Typography>
            <Typography>{user && user.emailId}</Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
