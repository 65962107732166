import InputLabel from "@mui/material/InputLabel";
import { observer } from "mobx-react-lite";
import { CircularProgress, Link } from "@mui/material";

interface Props {
  rfaUrl: string,
}

export default observer(function LinkRevitFamilies({ rfaUrl }: Props) {
  return (
    <>
      {rfaUrl &&
        <Link
          underline="always"
          variant="overline"
          href={rfaUrl}
          style={{ margin: "5px" }}
        >
          Download Revit Families Zip File
        </Link>
      }
      {!rfaUrl &&
        <>
          <InputLabel style={{ margin: "5px" }}>Loading Revit Families...</InputLabel>
          <CircularProgress style={{ margin: "5px" }} />
        </>}
    </>
  );
});
